.p-accordion {
  display: flex;
  flex-direction: column;

  .p-accordionpanel:last-child {
    .p-accordioncontent {
      .p-accordioncontent-content {
        border-bottom-left-radius: var(--p-accordion-header-last-bottom-border-radius);
        border-bottom-right-radius: var(--p-accordion-header-last-bottom-border-radius);
      }
    }
  }
}