p-inputNumber.p-component.borderless {
  & > input {
    border: unset;
    height: 2rem;
    outline: unset;
    box-shadow: unset;
  }

  .p-inputnumber-button {
    background: unset;
  }
}

.p-inputnumber-stacked {
  .p-inputnumber-increment-button {
    height: .75rem;
    border-end-start-radius: unset;
    border-start-end-radius: var(--p-inputtext-border-radius);
  }
  .p-inputnumber-decrement-button {
    height: .75rem;
  }
}