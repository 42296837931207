.p-disabled.p-menu-item {
  @apply tw-rounded-lg;

  .p-menu-item-content {
    @apply tw-rounded-lg;

    .p-menu-item-link {
      @apply tw-rounded-lg;
    }
  }
}