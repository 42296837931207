// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use './assets/scss/material/m3-theme';
@use "./assets/scss/utility" as utils;

/* You can add global styles to this file, and also import other style files */
@use "./assets/scss/primeng/primeng" as *;

@include mat.core();

@layer tailwind-base, primeng, calendar-pro, tailwind-utilities;

:root {
  --scrollbar-with: auto;
  --scrollbar-color: unset;
  --main-font-size: 14px;
}

/* Scrollbar Firefox */
* {
  scrollbar-width: var(--scrollbar-with);
  scrollbar-color: var(--scrollbar-color);
}

*::-webkit-scrollbar {
  width: 10px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  box-shadow: gray;
  border-radius: 0;
}

*::-webkit-scrollbar-thumb {
  background: #afb8bd;
  border-radius: 0;
}

*::-webkit-scrollbar-thumb:hover {
  background: #73838c;
}

html, body {
  @include mat.all-component-themes(m3-theme.$light-theme);

  & {
    height: 100%;
    width: 100%;
    margin: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    font-family: "Roboto","Segoe UI","GeezaPro","DejaVu Serif","sans-serif","-apple-system","BlinkMacSystemFont";
    font-size: var(--main-font-size);
  }
}

*, *:before, *:after {
  box-sizing: border-box;

  /* Disattiva selezione con doppio click sui testi */
  /*-webkit-touch-callout: none; !* iOS Safari *!
  -webkit-user-select: none; !* Safari *!
  -khtml-user-select: none; !* Konqueror HTML *!
  -moz-user-select: none; !* Old versions of Firefox *!
  -ms-user-select: none; !* Internet Explorer/Edge *!
  user-select: none; !* Non-prefixed version, currently
                        supported by Chrome, Edge, Opera and Firefox *!*/
}

:focus-visible {
  outline: none;
}

*::selection {
  background-color: #2979ff;
  color: white;
}

@-webkit-keyframes rotation
{
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

@keyframes rotation
{
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

@-webkit-keyframes turn
{
  0% {
    stroke-dashoffset: 180;
  }

  50% {
    stroke-dashoffset: 45;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 180;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

@keyframes turn
{
  0% {
    stroke-dashoffset: 180;
  }

  50% {
    stroke-dashoffset: 45;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 180;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

/* FIX per stampa pdf libreria ngx-extended-pdf-viewer */
@media print, p-dialog-mask {
  body {
    position: static;
    overflow: visible;
    width: auto;
    height: auto;
    max-width: none;
    max-height: none;
  }
}

::backdrop {
  background: rgba(0, 0, 0, 0.4);
}


/* Header with blue background and white text */
mat-toolbar{

  .e-ddl.e-input-group {

    .e-control-wrapper .e-input {
      color: utils.$base-secondary;
    }

    input.e-input::placeholder {
      color: utils.$base-secondary;
    }
  }

  /* DropDownList SyncFusion */
  .e-dropdownlist {
    margin-top: 0.8em;
  }

  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
  mat-toolbar .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after {
    color: utils.$base-secondary;
    border-color: utils.$base-secondary;
  }


  .e-control input.e-input,
  .e-control .e-input-group input,
  .e-control .e-input-group input.e-input,
  .e-control .e-input-group.e-control-wrapper input,
  .e-control .e-input-group.e-control-wrapper input.e-input,
  .e-control .e-float-input input,
  .e-control .e-float-input.e-control-wrapper input,
  .e-control.e-input-group input,
  .e-control.e-input-group input.e-input,
  .e-control.e-input-group.e-control-wrapper input,
  .e-control.e-input-group.e-control-wrapper input.e-input,
  .e-control.e-float-input input,
  .e-control.e-float-input.e-control-wrapper input {
    color: utils.$base-secondary;
  }

  .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap {
    border-color: utils.$base-secondary;
  }

  .e-input-group:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-float-input.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-float-input:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
  .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover,
  .e-float-input:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
  .e-float-input:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
  .e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]),
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) .e-input-in-wrap:hover {
    border-color: utils.$base-secondary;
  }

  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
    border-color: utils.$base-secondary;
  }

  .e-input-group .e-input-group-icon:last-child,
  .e-input-group.e-bigger .e-input-group-icon:last-child,
  .e-input-group .e-input-group-icon.e-bigger:last-child,
  .e-bigger .e-input-group .e-input-group-icon:last-child,
  .e-input-group.e-small .e-input-group-icon:last-child,
  .e-input-group.e-small.e-bigger .e-input-group-icon:last-child,
  .e-input-group.e-small .e-input-group-icon.e-bigger:last-child,
  .e-input-group.e-control-wrapper .e-input-group-icon:last-child,
  .e-input-group.e-control-wrapper.e-bigger .e-input-group-icon:last-child,
  .e-input-group.e-control-wrapper .e-input-group-icon.e-bigger:last-child,
  .e-input-group.e-control-wrapper.e-small .e-input-group-icon:last-child,
  .e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon:last-child,
  .e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger:last-child,
  .e-bigger .e-input-group.e-control-wrapper.e-small .e-input-group-icon:last-child,
  .e-bigger .e-input-group.e-small .e-input-group-icon:last-child {
    color: utils.$base-secondary;
  }

  .e-input-group:not(.e-disabled) .e-input-group-icon:hover,
  .e-input-group.e-control-wrapper:not(.e-disabled) .e-input-group-icon:hover {
    color: utils.$base-secondary;
  }

  ta-nav-user {
    margin-left: 10px;
    margin-right: 0;
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .mat-button-ripple.mat-ripple {
      border: 2px solid utils.$base-secondary;
    }
  }

  .e-ddl.e-input-group input[readonly] ~ .e-clear-icon:not(.e-clear-icon-hide),
  .e-float-input input[readonly] ~ .e-clear-icon:not(.e-clear-icon-hide),
  .e-float-input.e-input-group input[readonly] ~ .e-clear-icon:not(.e-clear-icon-hide) {
    color: utils.$base-secondary;
  }
}

/* "x" icon to toggle Sidebar */
.mat-mdc-unelevated-button.btn-toggle-drawer,
.mat-flat-button.btn-toggle-drawer:hover {
  background: none;
  color: rgb(51, 65, 85);
  margin: 0 2px;
}

.mat-mdc-unelevated-button.mat-primary:not([disabled]):not(.btn-toggle-drawer) {
  &:hover,
  &:focus,
  &:active {
    background-color: #2962ff;
  }

  &:active:focus,
  &:active:hover {
    background-color: #0d47a1;
  }
}

.mat-mdc-button.mat-mdc-button-disabled,
.mat-mdc-icon-button.mat-mdc-button-disabled,
.mat-mdc-stroked-button.mat-mdc-button-disabled,
.mat-mdc-flat-button.mat-mdc-button-disabled {
  cursor: not-allowed !important;
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  min-width: unset;
  height: unset;
  padding: 0 .25rem;
}

.cdk-virtual-scroll-content-wrapper {
  width: 100%;
}

[mat-flat-button] {
  min-width: 34px !important;
}

button {
  &:focus-visible {
    outline-width: 0;
  }
}

.flex {
  &-row-layout {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &-col-layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

// Override css jsTree
.vakata-context {
  z-index: 1;
  li {
    a {
      padding: 0 1rem;
      i, .vakata-contextmenu-sep {
        display: none;
      }
    }
  }
}

.vakata-context-separator {
  a {
    margin: 0 !important;
  }
}

.jstree-icon.jstree-themeicon.jstree-themeicon-custom {
  font-size: 1rem;
  line-height: 2rem;

  &.tree-gtw-offline {
    border-radius: 50%;
    color: white;
    background: #EC7063;
  }

  &.tree-gtw-online {
    border-radius: 50%;
    color: white;
    background-color: #009900;
  }

  &.tree-gtw-mixed {
    border-radius: 50%;
    color: white;
    background-color: #ffcc00;
  }
}

.jstree-default {
  .jstree-anchor {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

ul:focus-visible {
  outline: none;
}

.drop-indicator {
  display: block;
  z-index: 10;
  border-radius: 0.5rem;
  pointer-events: none;
  background-color: rgb(41 121 255);
  margin-left: 0.5rem;
  margin-right: 0.5rem;

  &.left-right {
    height: 100%;
    width: 4px;
  }

  &.up-down {
    height: 4px;
  }
}

// Tinymce Editor
.tox {

  .tox-menu {
    border-radius: .5rem !important;
  }

  .tox-collection__item--active:not(.tox-collection__item--state-disabled) {
    background: #ccdfff !important;
    color: #2979ff !important;
  }

  .tox-collection__item {
    border-radius: .5rem !important;
    &:hover {
      background: #f1f5f9 !important;
      color: #1e293b !important;
    }
  }

}