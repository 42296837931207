.p-popover {
  max-height: 97vh;
  &.hide-caret {
    &::before, &::after {
      display: none;
    }
  }
  &.no-padding {
    .p-popover-content {
      padding: 0;
    }
  }
}