/* In questo file vanno solo le personalizzazioni che non si riescono a fare tramite tema */
@use "primeicons/primeicons.css" as *;

/* Personalizzazioni tema base Lara */
@use "./components/inputnumber" as *;
@use "./components/select" as *;
@use "./components/splitter" as *;
@use "./components/accordion" as *;
@use "./components/popover" as *;
@use "./components/dialog" as *;
@use "./components/menu" as *;

/* Personalizzazioni non specifiche su singoli componenti */
.p-disabled, .p-component:disabled {
  opacity: var(--p-disabled-opacity);
  &:not(.p-button, .p-toggleswitch, .p-inputtext) {
    background: var(--p-inputtext-disabled-background);
    color: var(--p-inputtext-disabled-color);

    @at-root div#{&} {
      border-radius: var(--p-inputtext-border-radius);
      &.p-autocomplete > input {
        opacity: 1 !important;
        background: unset;
      }
    }
  }
}

.p-virtualscroller-content {
  width: 100%;
}